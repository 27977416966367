@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Header */
.header.open {
  position: fixed;
  height: 100vh;
  width: 100vw;
}
.mobile-nav-toggle {
  background-color: transparent;
  border: none;
  position: relative;
  padding: 0;
  height: 16px;
  width: 20px;
}
.bar1,
.bar2,
.bar3 {
  position: absolute;
  transform-origin: 50% 50%;
  transition: all 0.2s linear;
  height: 2px;
  width: 100%;
}
.bar1 {
  top: 0;
}
.bar2 {
  top: 7px;
}
.bar3 {
  top: 14px;
}
.header.open .bar1 {
  top: 7px;
  transform: rotate(45deg);
}
.header.open .bar2 {
  opacity: 0;
  width: 0;
}
.header.open .bar3 {
  top: 7px;
  transform: rotate(-45deg);
}
.compass-logo {
  fill: transparent;
  stroke-width: 6px;
  transform-origin: 50% 50%;
  transform: rotate(35deg);
}

/* Spinner */
.circle-loading {
  fill: transparent;
  stroke-dasharray: 283;
  stroke-linecap: round;
  stroke-width: 10px;
  transform-origin: 50% 50%;
  animation: 1.4s ease-in-out infinite both circle--loading;
}
@keyframes circle--loading {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }
  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }
  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}

/* Markdown */
.markdown-wrapper > * {
  margin-bottom: 0.7rem;
}
.markdown-wrapper > *:last-child {
  margin-bottom: 0 !important;
}
.markdown-wrapper a {
  color: #ff4400;
  word-break: break-all;
}
.markdown-wrapper a:hover {
  color: #ff6d38;
}
.markdown-wrapper pre {
  background-color: rgba(204, 204, 204, 0.4);
  max-width: 100%;
  padding: 0.8rem;
  overflow-x: auto;
}
.markdown-wrapper code {
  background-color: rgba(204, 204, 204, 0.4);
}
.markdown-wrapper pre > code {
  background-color: transparent;
}
.markdown-wrapper ul {
  list-style: disc outside none;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}
.markdown-wrapper ol {
  list-style: decimal outside none;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}
@media all and (min-width: 640px) {
  .markdown-wrapper > * {
    margin-bottom: 1rem;
  }
}
